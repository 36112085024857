import React, { Component } from "react";
import { Form, Layout, Modal, Spin, Row, Col, Radio, Button, Icon, Input } from "antd";
import "./index.scss";
import moment from "moment";
// import Countdown from "react-countdown";
import RegistrationModal from "./components/RegistrationModal";
import Scanner from "react-webcam-qr-scanner";

const { Content } = Layout;

class Event extends Component {
  initialState = {
    eventsData: [],
    event: {},
    qrCode: null,
    isLoadingState: false,
    showResultModal: false,
    isScanning: false
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    this.props.onGetEvents();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.eventsData !== nextProps.eventsData) {
      let event = {};
      const eventName = this.props.match.params.event;
      nextProps.eventsData.forEach((row) => {
        if (row.page_details.event_slug === eventName) {
          event = { ...row };
        }
      });

      this.setState({
        eventsData: nextProps.eventsData,
        event: event,
        isLoadingState: true
      })
    }
  }


  handleCloseModal = () => {
    this.setState({
      showResultModal: false,
      isScanning: false,
      qrCode: null
    });
  }

  handleDecode = (result) => {
    console.log("scan", result.data);
    if (this.state.isScanning == false) {
      const code = result.data;

      if (code !== "") {
        this.setState({
          isScanning: true,
          qrCode: code,
          showResultModal: true
        })
      }
    }
  }

  handleScannerLoad = (mode) => {
    // console.log("handleScannerLoad", mode);
  }

  getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then(stream => {
        let video = this.videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch(err => {
        console.error("error:", err);
      });
  };

  render() {
    const { isLoadingState, event, isScanning, showResultModal, qrCode } = this.state;
    const { isLoading } = this.props;
    const isDoneLoading = !isLoading && isLoadingState;

    if (!event) {
      return (
        <Spin spinning={!isDoneLoading}>
          <Layout className="" style={{ minHeight: "100vh", minWidth: "100%" }}>
            &nbsp;
          </Layout>
        </Spin>
      )
    }

    this.getVideo();

    return (
      <Layout>
        <Content>
          <div>{event.event_name}</div>
          {/* <div>
            <Input type="file" accept="image/*" capture="environment" />
          </div> */}

          <div>
            <Spin spinning={isScanning}>
              <Scanner
                className="some-classname"
                onDecode={this.handleDecode}
                onScannerLoad={this.handleScannerLoad}
                constraints={{
                  audio: false,
                  video: {
                    facingMode: "user"
                  }
                }}
                captureSize={{ width: 1280, height: 720 }}
              />
            </Spin>
          </div>

          {/* <div>
            <div>
              <button>Take a photo</button>
              <video ref={videoRef} />
              <canvas />
            </div>
          </div> */}
        </Content>

        <RegistrationModal qrCode={qrCode} event={event} showResultModal={showResultModal} handleCloseModal={this.handleCloseModal} {...this.props} />
      </Layout >
    );
  }
}

const EventForm = Form.create({ name: "event-form" })(Event);

export default EventForm;
