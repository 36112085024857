import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import login from "../components/Login/reducers";
import home from "../components/Home/reducers";
import event from "../components/Event/reducers";
import scan from "../components/Scan/reducers";

const rootReducer = combineReducers({
  form,
  login,
  home,
  event,
  scan
});

export default rootReducer;
