import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getEvents, getEvent, registerEvent, getEventBySlug } from "./actions";
import Page from "./Page";

class EventCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const EventForm = Form.create({ name: "event-form" })(EventCard);

function mapStateToProps(state) {
  return {
    isLoading: state.event.isLoading,
    eventsData: state.event.eventsData,
    eventData: state.event.eventData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetEvents: (data) => dispatch(getEvents(data)),
    onGetEvent: (data) => dispatch(getEvent(data)),
    onRegisterEvent: (data) => dispatch(registerEvent(data)),
    onGetEventBySlug: (data) => dispatch(getEventBySlug(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventForm);
