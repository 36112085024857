import React, { Component } from "react";
import { Form, Modal, Layout, Button, Row, Col, Spin } from "antd";
import "./index.scss";

const { Content } = Layout;

class DisclaimerModal extends Component {
  initialState = {
    showModal: false
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
    this.props.handleCloseDisclaimerModal();
  };

  handleAgreeButton = () => {
    this.setState({
      showModal: false,
    });
    this.props.handleAgreeButton();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showDisclaimerModal !== nextProps.showDisclaimerModal) {
      this.setState({ showModal: nextProps.showDisclaimerModal });
    }
  }

  render() {
    const { showModal } = this.state;
    const { isLoading, form: { getFieldDecorator }, pageDetails } = this.props;

    const primaryColor = pageDetails.primary_color;
    const primaryFontColor = pageDetails.primary_font_color;
    const secondaryColor = pageDetails.secondary_color;
    const secondaryFontColor = pageDetails.secondary_font_color;

    return (
      <Modal
        title={pageDetails.disclaimer_title}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        width={"700px"}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Spin spinning={isLoading} key="create-event-spin">
              <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                <Row type="flex" gutter={30} style={{ overflowY: "auto", maxHeight: "80vh", marginBottom: "20px" }}>
                  <Col span={24} align="center" style={{ marginBottom: "30px" }}>
                    <div dangerouslySetInnerHTML={{ __html: pageDetails.disclaimer_text }} />
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} align="center">
                    <Form.Item>
                      <Button
                        style={{ backgroundColor: secondaryColor, color: secondaryFontColor }}
                        type="default"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.handleCloseModal}
                      >
                        Decline
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        style={{ backgroundColor: primaryColor, color: primaryFontColor }}
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.handleAgreeButton}
                      >
                        Agree
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Content>
        </Layout >
      </Modal>
    );
  }
}

const DisclaimerModalComponent = Form.create({ name: "disclaimer-modal" })(DisclaimerModal);

export default DisclaimerModalComponent;
