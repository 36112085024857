import React, { Component } from "react";
import { Form, Modal, Layout, Button, Row, Col, Spin } from "antd";
import "./index.scss";

const { Content } = Layout;

class PopupModal extends Component {
  initialState = {
    showModal: false
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
    this.props.handleClosePopupModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showPopupModal !== nextProps.showPopupModal) {
      this.setState({ showModal: nextProps.showPopupModal });
    }
  }

  render() {
    // const { showModal } = this.state;
    const { isLoading, form: { getFieldDecorator }, pageDetails, showPopupModal } = this.props;

    const primaryColor = pageDetails.primary_color;
    const primaryFontColor = pageDetails.primary_font_color;
    const secondaryColor = pageDetails.secondary_color;
    const secondaryFontColor = pageDetails.secondary_font_color;

    return (
      <Modal
        title={pageDetails.popup_title}
        visible={showPopupModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        width={"700px"}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Spin spinning={isLoading} key="create-event-spin">
              <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                <Row type="flex" gutter={30} style={{ overflowY: "auto", maxHeight: "80vh", marginBottom: "20px" }}>
                  <Col span={24} align="center" style={{ marginBottom: "30px" }}>
                    <div dangerouslySetInnerHTML={{ __html: pageDetails.popup_text }} />
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} align="center">
                    <Form.Item>
                      <Button
                        style={{ backgroundColor: primaryColor, color: primaryFontColor }}
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.handleCloseModal}
                      >
                        Close
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Content>
        </Layout >
      </Modal>
    );
  }
}

const PopupModalComponent = Form.create({ name: "popup-modal" })(PopupModal);

export default PopupModalComponent;
