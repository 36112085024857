import React, { Component } from "react";
import { Form, Layout, Card, Modal, Icon } from "antd";
import { Route, Switch, Redirect, withRouter, Link } from "react-router-dom";
import moment from "moment";
import "./index.scss";

const { Content } = Layout;

class Home extends Component {
  initialState = {
    eventsData: []
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  // showModalResponse = (response, success, fail) => {
  //   if (response.error) {
  //     Modal.success({
  //       title: fail,
  //       content: "",
  //     });
  //   } else {
  //     Modal.success({
  //       title: success,
  //       content: "",
  //     });
  //   }
  // };

  componentDidMount() {
    this.props.onGetEvents();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.eventsData !== nextProps.eventsData) {
      this.setState({
        eventsData: nextProps.eventsData
      })
    }
  }

  render() {
    const { eventsData } = this.state;

    console.log(eventsData);

    return (
      <Layout className="home-container">
        <Content style={{ padding: "20px" }}>
          {
            eventsData.map(event => {
              let eventDate = moment(event.event_date_start).format("MMMM DD, YYYY");
              if (event.event_date_start == event.event_date_end) {
                eventDate = moment(event.event_date_start).format("MMMM DD, YYYY") + ' - ' + moment(event.event_date_end).format("MMMM DD, YYYY");
              }

              return (
                <div key={event.id}
                  style={{ padding: "20px", border: "1px solid #CCCCCC" }}>
                  <Link to={`/event/${event.page_details.event_slug}`}>
                    <div>
                      <h2>{event.event_name}</h2>
                      <h3>{eventDate}</h3>
                      <h3>{`${event.event_time_start} - ${event.event_time_end}`}</h3>
                    </div>
                  </Link>
                </div>
              );
            }
            )}
        </Content>
      </Layout >
    );
  }
}

const HomeForm = Form.create({ name: "home-form" })(Home);

export default HomeForm;
