import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { } from "./actions";
import { getEvents, getEvent } from "../Event/actions";
import { getRegistration, acceptAttendance } from "./actions";
import Page from "./Page";

class LayoutHeaderCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const LayoutHeaderForm = Form.create({ name: "layout-header-form" })(LayoutHeaderCard);

function mapStateToProps(state) {
  return {
    isLoading: state.scan.isLoading,
    eventsData: state.event.eventsData,
    eventData: state.event.eventData,
    registrationData: state.scan.registrationData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetEvents: (data) => dispatch(getEvents(data)),
    onGetEvent: (data) => dispatch(getEvent(data)),
    onGetRegistration: (data) => dispatch(getRegistration(data)),
    onAcceptAttendance: (data) => dispatch(acceptAttendance(data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutHeaderForm);
