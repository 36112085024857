const initialData = {
  isLoading: false,
  eventsData: [],
  eventData: {},
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_EVENTS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_EVENTS_SUCCESS":
      return {
        ...state,
        eventsData: action.payload.data,
        isLoading: false,
      };
    case "GET_EVENTS_FAILED":
      return {
        ...state,
        eventsData: [],
        isLoading: false,
      };

    case "CREATE_EVENT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "CREATE_EVENT_SUCCESS":
      return {
        ...state,
        eventData: action.payload.data,
        isLoading: false,
      };
    case "CREATE_EVENT_FAILED":
      return {
        ...state,
        eventData: {},
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
