import React, { Component } from "react";
import { Form, Modal, message, Layout, Button, Row, Col, Input, Radio, Checkbox, DatePicker, Select, Spin } from "antd";
import "./index.scss";
import { setEvent, deleteEvent } from "../../../../utils/token";
import ImageUploader from "../../../shared/ImageUploader";
import MaskedInput from "antd-mask-input";

const { Content } = Layout;

class RegistrationModal extends Component {
  initialState = {
    showModal: false
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.uploader = React.createRef();
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
    this.props.form.resetFields();
    this.props.handleCloseRegistrationModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.showRegistrationModal !== nextProps.showRegistrationModal) {
      this.setState({ showModal: nextProps.showRegistrationModal });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const event = this.props.event;

        const params = {
          values: { ...values },
          event_id: event.id,
          event_ticket_id: this.props.selectedTicket,
        };

        if (this.uploader.current !== null) {
          const file = await this.uploader.current.handleUploadRequest();
          if (file) {
            params.values[file.extra] = file.url;
          }
        }

        await this.props
          .onRegisterEvent(params)
          .then((response) => {
            if (response.response.data.status === "success") {
              message.success('Successfuly registered');
              // this.handleCloseModal();
              deleteEvent();
              setEvent(event);
              window.location.href = "/thank-you";
            } else {
              message.error('Error occured: ' + response.response.data.message);
            }
          })
          .catch(error => {
            console.log("error", error);
          });
      } else {
        console.log("error", err);
      }
    });
  }

  render() {
    const { showModal } = this.state;
    const { isLoading, form: { getFieldDecorator }, registrationFields, pageDetails, match } = this.props;

    const primaryColor = pageDetails.primary_color;
    const primaryFontColor = pageDetails.primary_font_color;
    const secondaryColor = pageDetails.secondary_color;
    const secondaryFontColor = pageDetails.secondary_font_color;

    return (
      <Modal
        title={`Register`}
        visible={showModal}
        onCancel={this.handleCloseModal}
        centered={true}
        footer={null}
        width={"700px"}
        maskClosable={false}
      // destroyOnClose={true}
      >
        <Layout className="overview-container">
          <Content className="overview-content" style={{ padding: "10px" }}>
            <Spin spinning={isLoading} key="create-event-spin">
              <Form onSubmit={this.handleSubmit} style={{ width: "100%" }} disabled={isLoading}>
                <Row type="flex" gutter={30}>
                  {registrationFields.map((row, index) => {
                    if (row.type == "Checkbox") {
                      let options = [];
                      row.options.split(",").forEach(row => {
                        options.push({
                          label: row,
                          value: row
                        });
                      });

                      return (
                        <Col span={12} key={index}>
                          <Form.Item label={row.label}>
                            {getFieldDecorator(`id-${row.id}`, {
                              rules: [
                                {
                                  required: parseInt(row.is_required) == 1 ? true : false,
                                  message: "Please enter " + row.label,
                                },
                              ],
                            })(<Checkbox.Group options={options} />)}
                          </Form.Item>
                        </Col>
                      );
                    }

                    if (row.type == "Date") {
                      return (
                        <Col span={12} key={index}>
                          <Form.Item label={row.label}>
                            {getFieldDecorator(`id-${row.id}`, {
                              rules: [
                                {
                                  required: parseInt(row.is_required) == 1 ? true : false,
                                  message: "Please enter " + row.label,
                                },
                              ],
                            })(<Input type={"date"} />)}
                          </Form.Item>
                        </Col>
                      );
                    }

                    if (row.type == "Dropdown") {
                      return (
                        <Col span={12} key={index}>
                          <Form.Item label={row.label}>
                            {getFieldDecorator(`id-${row.id}`, {
                              rules: [
                                {
                                  required: parseInt(row.is_required) == 1 ? true : false,
                                  message: "Please enter " + row.label,
                                },
                              ],
                            })(
                              <Select>
                                {row.options.split(",").map(row => {
                                  return (<Select.Option value={row}>{row}</Select.Option>)
                                })}
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      );
                    }

                    if (row.type == "Email") {
                      return (
                        <Col span={12} key={index}>
                          <Form.Item label={row.label}>
                            {getFieldDecorator(`id-${row.id}`, {
                              rules: [
                                {
                                  required: parseInt(row.is_required) == 1 ? true : false,
                                  message: "Please enter " + row.label,
                                },
                              ],
                            })(<Input type={"email"} />)}
                          </Form.Item>
                        </Col>
                      );
                    }


                    if (row.type == "File Upload") {
                      return (
                        <Col span={12} key={index}>
                          <Form.Item label={row.label}>
                            <ImageUploader
                              match={match}
                              ref={this.uploader}
                              fileFolder={"uploads/registration"}
                              buttonDesc="Click here to select file to be uploaded"
                              imageUrl={null}
                              filename={null}
                              extra={`id-${row.id}`}
                            />
                            {/* {getFieldDecorator(`id-${row.id}-file`, {
                              rules: [
                                {
                                  required: parseInt(row.is_required) == 1 ? true : false,
                                  message: "Please select file",
                                },
                              ],
                            })(<Input type={"file"} />)} */}
                          </Form.Item>
                        </Col>
                      );
                    }

                    if (row.type == "Mobile") {
                      return (
                        <Col span={12} key={index}>
                          <Form.Item label={row.label}>
                            {getFieldDecorator(`id-${row.id}`, {
                              rules: [
                                {
                                  required: parseInt(row.is_required) == 1 ? true : false,
                                  message: "Please enter " + row.label,
                                },
                              ],
                            })(<MaskedInput mask={"+630000000000"} />)}
                          </Form.Item>
                        </Col>
                      );
                    }

                    if (row.type == "Number") {
                      return (
                        <Col span={12} key={index}>
                          <Form.Item label={row.label}>
                            {getFieldDecorator(`id-${row.id}`, {
                              rules: [
                                {
                                  required: parseInt(row.is_required) == 1 ? true : false,
                                  message: "Please enter " + row.label,
                                },
                              ],
                            })(<Input type={"number"} />)}
                          </Form.Item>
                        </Col>
                      );
                    }

                    if (row.type == "Radio") {
                      let options = [];
                      row.options.split(",").forEach(row => {
                        options.push({
                          label: row,
                          value: row
                        });
                      });

                      return (
                        <Col span={12} key={index}>
                          <Form.Item label={row.label}>
                            {getFieldDecorator(`id-${row.id}`, {
                              rules: [
                                {
                                  required: parseInt(row.is_required) == 1 ? true : false,
                                  message: "Please enter " + row.label,
                                },
                              ],
                            })(<Radio.Group options={options} />)}
                          </Form.Item>
                        </Col>
                      );
                    }

                    if (row.type == "Text") {
                      return (
                        <Col span={12} key={index}>
                          <Form.Item label={row.label}>
                            {getFieldDecorator(`id-${row.id}`, {
                              rules: [
                                {
                                  required: parseInt(row.is_required) == 1 ? true : false,
                                  message: "Please enter " + row.label,
                                },
                              ],
                            })(<Input />)}
                          </Form.Item>
                        </Col>
                      );
                    }

                    return ("");
                  })}
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        style={{ backgroundColor: secondaryColor, color: secondaryFontColor }}
                        type="default"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.handleCloseModal}
                      >
                        Close
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        style={{ backgroundColor: primaryColor, color: primaryFontColor }}
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Register
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Content>
        </Layout >
      </Modal>
    );
  }
}

const RegistrationModalComponent = Form.create({ name: "registration-modal" })(RegistrationModal);

export default RegistrationModalComponent;
