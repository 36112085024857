import Axios from "../../../utils/axios";

export const getEvents = (data) => {
    return (dispatch) => {
        Axios.get("/event-list-with-details", dispatch, "GET_EVENTS", data);
    };
};

export const getEvent = (data) => {
    return (dispatch) => {
        Axios.get("/event-with-details/" + data.event_id, dispatch, "GET_EVENT", data);
    };
};

export const getEventBySlug = (data) => {
    return (dispatch) => {
        Axios.get("/event-with-details-by-slug/" + data.slug, dispatch, "GET_EVENT", data);
    };
};

export const registerEvent = (data) => {
    return (dispatch) => {
        return Axios
            .post("/register", dispatch, "REGISTER", data)
            .then((response) => {
                // if (response.response.data.status == "success") {
                //     const param = {
                //         event_id: response.response.data.data.event_id
                //     };
                //     // dispatch(getEvents());
                //     dispatch(getEvent(param));
                // }
                return response;
            });
    };
};