import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { getEvents, getEvent } from "../Event/actions";
import Page from "./Page";

class ThankYouCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const ThankYouForm = Form.create({ name: "thank-you-form" })(ThankYouCard);

function mapStateToProps(state) {
  return {
    isLoading: state.event.isLoading,
    eventsData: state.event.eventsData,
    eventData: state.event.eventData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetEvents: (data) => dispatch(getEvents(data)),
    onGetEvent: (data) => dispatch(getEvent(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouForm);
