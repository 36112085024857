import React, { Component } from "react";
import { Form, Layout, Modal, Spin, Row, Col, Button, Icon } from "antd";
import "./index.scss";
import { getEvent } from "../../utils/token";
import moment from "moment";

const { Content } = Layout;

class ThankYou extends Component {
  initialState = {
    eventData: {},
    isLoadingState: false,
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    const eventSession = getEvent();
    const params = {
      event_id: eventSession.id
    };
    this.props.onGetEvent(params);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.eventData !== nextProps.eventData) {
      this.setState({
        eventData: nextProps.eventData,
        isLoadingState: true
      })
    }
  }

  handleBackButton = () => {
    window.location.href = "/event/" + this.state.eventData.page_details.event_slug;
  }

  render() {
    // const { id } = useParams();
    const { eventData, isLoadingState } = this.state;
    const { isLoading, match } = this.props;

    const isDoneLoading = !isLoading && isLoadingState;
    if (!eventData.id) {
      return (
        <Spin spinning={!isDoneLoading}>
          <Layout className="" style={{ minHeight: "100vh", minWidth: "100%" }}>
            &nbsp;
          </Layout>
        </Spin>
      )
    }

    const pageDetails = eventData.page_details;
    const primaryColor = pageDetails.primary_color;
    const primaryFontColor = pageDetails.primary_font_color;
    const secondaryColor = pageDetails.secondary_color;
    const secondaryFontColor = pageDetails.secondary_font_color;

    return (
      <Spin spinning={!isDoneLoading}>
        <Layout className="" style={{ padding: "10px", backgroundColor: "#FAFAFA", minHeight: "100vh" }}>
          <Content>
            <Row justify="center" gutter={30} style={{ maxWidth: "1000px", marginLeft: "auto", marginRight: "auto" }}>
              <Col span={24}>
                <Row style={{ margin: "10px 5px 20px 5px" }} gutter={30}>
                  <Col xs={0} sm={4} style={{
                    height: "50px",
                    width: "50px",
                    lineHeight: "50px",
                    position: "relative",
                    padding: "0"
                  }}>
                    <img src={pageDetails.display_photo ? `${process.env.REACT_APP_S3_BUCKET}${pageDetails.display_photo}` : ''}
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        verticalAlign: "top"
                      }}
                    />
                  </Col>
                  <Col xs={0} sm={20}>
                    <h2>{eventData.event_name}</h2>
                    {pageDetails.enable_date_time == 1 ? (
                      <div>
                        <Icon type="schedule" />
                        &nbsp;&nbsp;
                        <span>
                          <b>{`${moment(eventData.event_date_start).format("MMM. DD")}, ${eventData.event_time_start}`}</b> to <b>{`${moment(eventData.event_date_end).format("MMM. DD")}, ${eventData.event_time_end}`}</b>
                        </span>
                        &nbsp;
                        <span>
                          {eventData.timezone}
                        </span>
                      </div>
                    ) : ''}
                    {pageDetails.enable_address == 1 && (eventData.address1 || eventData.address2 || eventData.address3) ? (
                      <div>
                        <Icon type="pushpin" />
                        &nbsp;&nbsp;
                        <span>
                          <b>{`${eventData.address1 ?? ''} ${eventData.address2 ?? ''} ${eventData.address3 ?? ''}`}</b>
                        </span>
                      </div>
                    ) : ''}

                  </Col>

                  <Col xs={6} sm={0} style={{
                    height: "50px",
                    width: "50px",
                    lineHeight: "50px",
                    position: "relative",
                    padding: "0"
                  }}>
                    <img src={pageDetails.display_photo ? `${process.env.REACT_APP_S3_BUCKET}${pageDetails.display_photo}` : ''}
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        verticalAlign: "middle"
                      }}
                    />
                  </Col>
                  <Col xs={18} sm={0} style={{
                    height: "50px",
                    position: "relative",
                    lineHeight: "50px",
                    padding: "0 10px"
                  }}>
                    <h2 style={{ verticalAlign: "middle" }}>{eventData.event_name}</h2>
                  </Col>
                  <Col xs={24} sm={0} style={{ padding: 0 }}>
                    {pageDetails.enable_date_time == 1 ? (
                      <div style={{ fontSize: ".8rem" }}>
                        <Icon type="schedule" />
                        &nbsp;&nbsp;
                        <span>
                          <b>{`${moment(eventData.event_date_start).format("MMM. DD")}, ${eventData.event_time_start}`}</b> to <b>{`${moment(eventData.event_date_end).format("MMM. DD")}, ${eventData.event_time_end}`}</b>
                        </span>
                        &nbsp;
                        <span>
                          {eventData.timezone}
                        </span>
                      </div>
                    ) : ''}
                    {pageDetails.enable_address == 1 && (eventData.address1 || eventData.address2 || eventData.address3) ? (
                      <div>
                        <Icon type="pushpin" />
                        &nbsp;&nbsp;
                        <span>
                          <b>{`${eventData.address1 ?? ''} ${eventData.address2 ?? ''} ${eventData.address3 ?? ''}`}</b>
                        </span>
                      </div>
                    ) : ''}

                  </Col>
                </Row>

                <Row style={{ margin: "5px 5px 20px 5px" }}>
                  <Col span={24} style={{
                    // backgroundColor: "#F5F5F5",
                    backgrounColor: "transparent",
                    // minHeight: "300px",
                    // lineHeight: "300px",
                    position: "relative",
                    // borderRadius: "10px",
                    textAlign: "center",
                    margin: "20px, 0",
                  }}>
                    <img src={pageDetails.cover_photo ? `${process.env.REACT_APP_S3_BUCKET}${pageDetails.cover_photo}` : ''}
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        verticalAlign: "middle",
                        borderRadius: "10px"
                      }}
                    />
                  </Col>
                </Row>

                <Row style={{ margin: "20px" }} gutter={10}>
                  <Col xs={24} sm={24} style={{ padding: "10px", backgroundColor: "#FFF", borderRadius: "5px", border: "solid 1px #F5F5F5" }}>
                    <div dangerouslySetInnerHTML={{ __html: eventData.page_details.thank_you_text }} />
                  </Col>
                </Row>

                <Row type="flex" gutter={30}>
                  <Col span={24} align="center">
                    <Form.Item>
                      <Button
                        style={{ backgroundColor: primaryColor, color: primaryFontColor }}
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={this.handleBackButton}
                      >
                        Back to registration
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    );
  }
}

const ThankYouForm = Form.create({ name: "thank-you-form" })(ThankYou);

export default ThankYouForm;
