import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Form } from "antd";
import { submitLogin, submitForgot } from "./actions";
import Page from "./Page";

class Login extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const LoginForm = Form.create({ name: "login_form" })(Login);

function mapStateToProps(state) {
  return {
    loggingIn: state.login.loggingIn,
    loggedIn: state.login.loggedIn,
    loginRejected: state.login.loginRejected,
    isLoading: state.login.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitLogin: (data) => dispatch(submitLogin(data)),
    onSubmitForgot: (data) => dispatch(submitForgot(data)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm)
);
